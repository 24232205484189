/*
函数作用：列表详情返回列表，记录搜索字段

使用方法：

  1，created钩子函数中调用 rememberPageList(this) 回填缓存中记录的搜索数据;

  2，跳转详情时调用 rememberPageList(this, ['name','status','pageData']) 记录要存储的字段明;

*/

const rememberPageList = function(that, nameList) {
  // arr 需要记录字段名，如：['search', 'pageData']
  if (nameList) {
    // 记录页数和查询信息
    let pageListInfo = {
      routePath: that.$route.path
    };
    nameList.forEach(item => {
      pageListInfo[item] = that[item];
    })
    sessionStorage.setItem('rememberPageListInfo', JSON.stringify(pageListInfo));
  } else {
    let str = sessionStorage.getItem('rememberPageListInfo')
    if (str) {
      try {
        let row = JSON.parse(str);
        if (row.routePath === that.$route.path) {
          for (let key in row) {
            if (key in that) {
              that[key] = row[key]
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    sessionStorage.removeItem('rememberPageListInfo');
  }
}
export default rememberPageList
