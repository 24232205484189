<template>
  <div class="refund_content">
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="getSearchList"
    >
      <div>
        <p>订单编号</p>
        <el-input
          v-model="searchData.orderNo"
          size="mini"
          placeholder="请输入完整订单编号"
          clearable
          @clear="getSearchList"
        />
      </div>
      <div>
        <p>售后状态</p>
        <el-select
          v-model="searchData.refundStatus"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
          @change="getSearchList"
        >
          <el-option
            v-for="item in Object.keys(REFUND_STATUS_MAP)"
            :key="item"
            :label="REFUND_STATUS_MAP[item]"
            :value="item"
          />
        </el-select>
      </div>
      <div>
        <p>介入状态</p>
        <el-select
          v-model="searchData.interveneStatus"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
          @change="getSearchList"
        >
          <el-option
            v-for="item in Object.keys(INTERVENESTATUS_MAP)"
            :key="item"
            :label="INTERVENESTATUS_MAP[item]"
            :value="item"
          />
        </el-select>
      </div>
      <div>
        <p>申请时间</p>
        <el-date-picker
          :value="[searchData.applyBeginTime, searchData.applyEndTime]"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          size="mini"
          :clearable="false"
          :editable="false"
          @input="changeTime"
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          关联平台
        </p>
        <el-select
          v-model="searchData.projectCode"
          size="mini"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="(item,i) in platformConfigList"
            :key="i"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="onExport"
      >
        导 出
      </el-button>
    </SearchPanel>

    <div style="margin-bottom: 20px">
      <el-radio-group
        v-model="searchData.titleType"
        size="mini"
        @change="getSearchList"
      >
        <el-radio-button
          v-for="item in Object.keys(TITLETYPE_MAP)"
          :key="item"
          :label="item"
        >
          {{ TITLETYPE_MAP[item] }}
        </el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="orderNo"
        label="订单编号"
        min-width="200px"
      >
        <template slot-scope="{ row }">
          <div>{{ row.orderNo }}</div>
          <div
            v-if="row.refundType === 4"
            style="color: red"
          >
            社区团购
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="specKey"
        label="商品信息"
        min-width="200"
      >
        <template slot-scope="{row}">
          <span>{{ row.goodsName }}: </span>
          <span>{{ row.skuName }}</span>
          <span style="padding-left: 12px">*{{ row.sellCount }}</span>
          <span style="padding-left: 24px">{{ row.sellPrice }}元</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        prop="belongSupplierName"
        label="供应商"
      />
      <el-table-column
        prop="totalFee"
        label="优惠金额"
      />
      <el-table-column
        prop="sellTotal"
        label="商品总额"
      />
      <el-table-column
        v-if="isMemberStore"
        prop="membershipCardFee"
        label="会员卡/工会福利支付"
      />
      <el-table-column
        prop="payment"
        label="实付金额"
      />
      <el-table-column
        prop="applyRefundFee"
        label="售后申请金额"
      />
      <el-table-column
        prop="refundFee"
        label="售后退款金额"
      />
      <!-- <el-table-column
        prop="logisticStatus"
        label="物流状态"
      >
        <template slot-scope="{row}">
          <el-button
            v-if="row.logisticStatus"
            type="text"
            size="mini"
            @click="handleRefundTrack(row)"
          >
            {{ LOGISTIC_STATUS_MAP[row.logisticStatus] || '' }}
          </el-button>
          <div v-else>
            暂无
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="refundStatusName"
        label="售后状态"
        min-width="110px"
      />
      <el-table-column
        prop="interveneStatusName"
        label="介入状态"
        min-width="110px"
      />
      <el-table-column
        label="申请时间"
        width="150"
      >
        <template slot-scope="{row}">
          {{ formatDate(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        prop="projectName"
        label="关联平台"
      />
      <el-table-column
        label="操作"
        fixed="right"
        width="90"
      >
        <template slot-scope="{row}">
          <div style="padding: 4px 0 0">
            <el-badge :is-dot="!!row.showRed">
              <el-button
                type="text"
                size="mini"
                @click="handleRefundStatus(row)"
              >
                查看详情
              </el-button>
            </el-badge>
          </div>
          <!-- <el-button
            v-if="row.logisticStatus"
            type="text"
            size="mini"
            @click="handleRefundTrack(row)"
          >
            查看物流
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      small
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { isApp } from '../../../../utils/isApp.js';
import rememberPageList from '@/utils/rememberPageList.js';
import { downloadFile } from '@/utils/download';
import { platformConfigList } from '@/utils/projectConfig';
import {
  REFUND_STATUS_MAP,
  LOGISTIC_STATUS_MAP,
  TITLETYPE_MAP,
  INTERVENESTATUS_MAP,
} from '../normals';

export default {
  name: 'refund-list',
  components: {

  },
  data() {
    return {
      platformConfigList,
      REFUND_STATUS_MAP,
      LOGISTIC_STATUS_MAP,
      TITLETYPE_MAP,
      INTERVENESTATUS_MAP,
      loading: false,
      searchData: {
        orderNo: '', // 订单编号
        refundStatus: '',
        titleType: 0, // 快捷筛选
        interveneStatus: '', // 介入状态
        applyBeginTime: new Date(dayjs().format('YYYY-MM-DD 00:00:00')).getTime() - 7 * 24 * 60 * 60 * 1000, // 申请开始时间
        applyEndTime: new Date(dayjs().format('YYYY-MM-DD 00:00:00')).getTime() + 24 * 60 * 60 * 1000 - 1000, // 申请结束时间
        projectCode: '',
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      count: 0,
      isMemberStore: false, // 用户信息
    };
  },
  created() {
    rememberPageList(this);
    this.getList();
    try {
      this.isMemberStore = JSON.parse(localStorage.getItem('userInfo')).isMemberStore;
    } catch (err) {
      console.log(err);
    }
  },
  mounted() {
    localStorage.setItem('appName', 'gys');
    const from = isApp();
    let navbar = document.getElementById('navbar');
    if (from && navbar) {
      navbar.style.opacity = '0';
    } else {
      navbar.style.opacity = '1';
    }
  },
  methods: {
    dayjs,
    // 导出
    onExport() {
      this.$confirm('是否确认导出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios({
          method: 'get',
          url: this.$api.refund.refund_export,
          params: {
            ...this.searchData,
            titleType: +this.searchData.titleType === 0 ? '' : this.searchData.titleType, // 快捷筛选
          },
          responseType: 'blob',
        }).then((res) => {
          let start = dayjs(this.searchData.applyBeginTime).format('YYYY-MM-DD');
          let end = dayjs(this.searchData.applyEndTime).format('YYYY-MM-DD');
          downloadFile(res, `订单售后_${start}_${end}.xls`);
        });
      }).catch(() => {
      });
    },
    // 时间筛选
    changeTime(val) {
      console.log(val);
      // if ((val[1] - val[0]) > 30 * 24 * 60 * 60 * 1000) {
      //   this.$message.error('选择时间不能超过三十天');
      //   [this.searchData.applyBeginTime] = val;
      //   this.searchData.applyEndTime = this.searchData.applyBeginTime + 31 * 24 * 60 * 60 * 1000 - 1000;
      // } else {
      [this.searchData.applyBeginTime, this.searchData.applyEndTime] = val;
      this.searchData.applyEndTime += 24 * 60 * 60 * 1000 - 1000;
      // }
      this.getSearchList();
    },
    getSearchList() {
      this.isSearch = true;
      this.currentPage = 1;
      this.getList();
    },
    resetSearch() {
      this.searchData = {
        orderNo: '',
        refundStatus: '',
        titleType: 0, // 快捷筛选
        interveneStatus: '', // 介入状态
        applyBeginTime: new Date().getTime() - 7 * 24 * 60 * 60 * 1000, // 申请开始时间
        applyEndTime: new Date().getTime(), // 申请结束时间
        projectCode: '',
      };
      this.getList();
    },
    getList() {
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        ...this.searchData,
        titleType: +this.searchData.titleType === 0 ? '' : this.searchData.titleType, // 快捷筛选
      };
      this.loading = true;
      this.$axios({
        method: 'get',
        url: this.$api.refund.refundList,
        params,
      }).then((res) => {
        const r = res || {};
        if (r.code === 0) {
          this.tableData = r.data.records;
          this.count = Number(r.data.total);
        } else {
          this.tableData = [];
        }
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.isSearch = false;
      this.getList();
    },
    handleRefundStatus(row) {
      rememberPageList(this, ['searchData', 'currentPage', 'pageSize']);
      this.$router.push({
        path: '/nb/order/refund/detail',
        query: {
          refundId: row.refundId,
        },
      });
    },
    formatDate(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
    handleRefundTrack({ logisticNo, logisticCode, logisticName }) {
      this.$router.push({
        path: '/nb/order/refund/track',
        query: {
          logisticNo, logisticCode, logisticName,
        },
      });
      // const routeData = this.$router.resolve({
      //   path: '/nb/order/refund/track',
      //   query: {
      //     logisticNo, logisticCode, logisticName
      //   }
      // })
      // window.open(routeData.href, '_blank')
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: #409EFF;
  margin-right: 5px;
}
.refund_content {
  background: #fff;
  padding: 20px;
}
</style>
