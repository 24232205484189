// 商品活动类型
const GOODS_TYPE_MAP = {
  1: '换购商品',
  2: '拼团商品',
  3: '秒杀',
  4: '大促/直降',
  5: '限时抢购',
  6: '新人',
};
/*
  订单列表
*/
const ORDER_STATE_ARR = [{
  value: 1,
  label: '待支付',
}, {
  value: 2,
  label: '待确认',
}, {
  value: 3,
  label: '待发货',
}, {
  value: '1',
  label: '待发货',
}, {
  value: '6',
  label: '待收货',
}, {
  value: '7',
  label: '已收货',
}, {
  value: '8',
  label: '交易完成',
}, {
  value: '10',
  label: '交易关闭',
}];

const ORDER_TYPE = {
  0: '普通卖品',
  1: '组合卖品',
  2: '普通非卖品',
  3: '组合非卖品',
};

const AFTER_SALE_ARR = [
  {
    value: '-1',
    label: '全部',
  }, {
    value: '1',
    label: '无售后/售后关闭',
  }, {
    value: '2',
    label: '售后中',
  }, {
    value: '3',
    label: '退款中',
  }, {
    value: '4',
    label: '退款成功',
  }, {
    value: '5',
    label: '退款失败',
  },
];

const REFUND_STATUS_MAP = {
  1: '待商家退款',
  2: '退款中',
  3: '(商家拒绝退款)待用户处理',
  4: '平台介入中',
  5: '退款成功',
  6: '退款关闭',
  11: '待商家处理',
  12: '待买家退货',
  13: '待买家处理',
  14: '平台介入中',
};
// 快捷筛选
const TITLETYPE_MAP = {
  0: '全部',
  1: '待小二处理',
  2: '待商家处理',
  3: '待买家处理',
  4: '待商家举证',
  5: '待买家举证',
  6: '举证超时',
};
// 介入状态
const INTERVENESTATUS_MAP = {
  1: '协商中',
  2: '平台处理中',
  3: '已处理',
  4: '已关闭',
};
const REFUND_TYPE_MAP = {
  1: '仅退款',
  2: '退货退款',
  3: '民宿退款',
};
// 货物状态
const DELIVER_STATUS_MAP = {
  1: '未收到货',
  2: '已收到货',
};
// 补充举证时间（天）
const LIMITDAY_MAP = {
  1: '24小时',
  2: '48小时',
  3: '72小时',
  5: '5天',
  7: '7天',
};

const LOGISTIC_STATUS_MAP = {
  '-1': '待揽收',
  0: '在途',
  1: '揽收',
  2: '疑难',
  3: '签收',
  4: '退签',
  5: '派件',
  6: '退回',
  7: '转单',
  10: '待清关',
  11: '清关中',
  12: '已清关',
  13: '清关异常',
  14: '收件人拒签',
};

const ORDER_STATUS_MAP = {
  1: '待支付',
  2: '待确认',
  3: '待成团',
  4: '待发货',
  5: '待使用',
  6: '待收货',
  7: '交易成功',
  8: '交易关闭',
};

const GOODS_STATUS_MAP = {
  1: '待支付',
  2: '待发货',
  3: '待收货',
  4: '交易成功',
  5: '交易关闭',
};
const REFUND_REASON_MAP = {
  1: '商品与描述不符',
  2: '商品质量变质/发霉/有异物',
  3: '少发/漏发',
  4: '包装/商品破损',
  5: '商家发错货',
  6: '没有收到货',
  7: '其他',
};

const REFUSE_REASON_ARR = [{
  label: '消费者未举证/举证不足',
  value: 1,
}, {
  label: '退款金额不合理，还需协商',
  value: 2,
}, {
  label: '其他',
  value: 3,
}];

const SHIPPING_TYPE_MAP = {
  0: '',
  1: '普通快递',
  2: '同城配送',
  3: '自提',
};
// 结算状态
const ORDER_SETTLE_STATUS = {
  1: '待结算',
  2: '可提现',
  3: '待打款',
  4: '已提现',
};
const APPLY_ORDER_TYPE = {
  1: '买家修改',
  2: '卖家修改',
};
const ORDER_STATUS = {
  0: '待处理',
  1: '已同意',
  2: '已拒绝',
};
const REJECT_REASON = {
  1: '新地址无法配送',
  2: '快递已取货，无法修改地址',
};
export {
  ORDER_STATE_ARR,
  AFTER_SALE_ARR,
  REFUND_STATUS_MAP,
  TITLETYPE_MAP,
  INTERVENESTATUS_MAP,
  REFUND_TYPE_MAP,
  DELIVER_STATUS_MAP,
  LIMITDAY_MAP,
  LOGISTIC_STATUS_MAP,
  ORDER_STATUS_MAP,
  GOODS_STATUS_MAP,
  REFUND_REASON_MAP,
  REFUSE_REASON_ARR,
  SHIPPING_TYPE_MAP,
  ORDER_SETTLE_STATUS,
  APPLY_ORDER_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
  REJECT_REASON,
  GOODS_TYPE_MAP,
};
